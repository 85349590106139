import { render, staticRenderFns } from "./OffersSection.vue?vue&type=template&id=92335fb8&scoped=true&"
import script from "./OffersSection.vue?vue&type=script&lang=js&"
export * from "./OffersSection.vue?vue&type=script&lang=js&"
import style0 from "./OffersSection.vue?vue&type=style&index=0&id=92335fb8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92335fb8",
  null
  
)

export default component.exports